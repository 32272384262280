import type { CaptureContext } from '@sentry/types'
import type { YscError } from '~/utils/error/YscError'
import { BOOKING_GUEST_INVALID_COUPON_CODE, CAMPER_DELETED, CAMPER_NOT_FOUND, CAMPER_PRICE_INVALID_DOCUMENTS, INVALID_PLACE, SEARCH_INVALID_PAGE, PAYMENT_CARD_EXPIRES_BEFORE_BOOKING_END } from '~/utils/error/errorFingerprints'

export function useErrorReport() {
  const { $sentry } = useNuxtApp()

  // Fingerprints that we do not want to report to sentry
  const excludedFingerprints = [CAMPER_NOT_FOUND, CAMPER_DELETED, SEARCH_INVALID_PAGE, CAMPER_PRICE_INVALID_DOCUMENTS, INVALID_PLACE, BOOKING_GUEST_INVALID_COUPON_CODE, PAYMENT_CARD_EXPIRES_BEFORE_BOOKING_END]

  return (error: YscError | Error) => {
    const additionalData: CaptureContext = {}
    if ('sentryAdditionalData' in error && error.sentryAdditionalData) {
      additionalData.extra = error.sentryAdditionalData
    }

    if ('sentryTags' in error && error.sentryTags) {
      additionalData.tags = error.sentryTags
    }

    if ('fingerprint' in error && excludedFingerprints.includes(error.fingerprint)) {
      if (import.meta.dev) {
        console.info(`${new Date().toISOString()}\tCaptured error not sent to sentry: ${error.message} [fingerprint:${error.fingerprint}]`)
      }
    }
    else {
      $sentry.captureException(error, additionalData)
    }
  }
}
