import {
  init,
  setContext,
  setUser,
  setTag,
  addBreadcrumb,
  captureMessage,
  captureException,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/vue'
import { sentryBeforeSend as beforeSend } from '~/utils/sentryBeforeSend'
import { sentryIgnoreErrors as ignoreErrors } from '~/utils/sentryIgnoreErrors'

export default defineNuxtPlugin({
  name: 'ysc:sentry',
  parallel: true,
  setup() {
    const {
      public: {
        app: { version },
        sentry: { common: sentryCommonConfig, client: sentryClientConfig },
        api: { rental: { url: pathApiRental } },
        featureFlag: { sentryReplay },
      },
    } = useRuntimeConfig()
    const { vueApp, ssrContext } = useNuxtApp()
    const router = useRouter()
    const route = useRoute()

    const sentry = {
      setContext,
      setUser,
      setTag,
      addBreadcrumb,
      captureMessage,
      captureException,
    }

    if (import.meta.server) {
      // $sentry object is defined by the nitro plugin in /server/plugins
      const sentryServer = ssrContext?.event.context.$sentry as typeof sentry
      return {
        provide: {
          sentry: sentryServer,
        },
      }
    }

    init({
      ...sentryCommonConfig,
      ...sentryClientConfig,
      app: vueApp,
      release: version,
      integrations: [
        browserTracingIntegration({
          router,
        }),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
          networkDetailAllowUrls: [
            window.location.origin,
            pathApiRental,
          ],
          networkRequestHeaders: ['Cache-Control'],
          networkResponseHeaders: ['Referrer-Policy'],
          beforeErrorSampling: (error) => {
            // Check error.level to be sure to not record on console.warn related to sentry issue https://github.com/getsentry/sentry/issues/70028
            if (!sentryReplay || error.level === 'warning') {
              return false
            }
            const targetPagesToRecordPattern = /^(campers-id-request|booking-payment)/
            return route.matched.some((routeSegment) => {
              return routeSegment.name !== undefined && targetPagesToRecordPattern.test(routeSegment.name.toString())
            })
          },
        }),
      ],
      tracePropagationTargets: ['localhost:3000'],
      ignoreErrors,
      beforeSend,
    })

    return {
      provide: {
        sentry,
      },
    }
  },
})
