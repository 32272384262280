import type { AppReferential } from '@yescapa-dev/ysc-api-js/modern'

export const useReferentialStore = defineStore('referential', () => {
  const referential = ref<AppReferential | null>(null)

  const orderedCountryList = computed(() => [...getReferentialOptions('countries_main'), ...getReferentialOptions('countries_others')])
  const searchCountryRedirectedToGoboony = computed(() => Object.keys(referential.value?.countries_booking_eligibility ?? {}) ?? [])

  const { localeProperties: { yscCode } } = useLocaleProperties()
  const fetchReferential = async () => {
    if (referential.value) {
      return
    }

    referential.value = await $fetch('/api/internals/referential', {
      query: {
        lang: yscCode,
      },
    })
  }

  const getReferentialMaps = <K extends keyof AppReferential['maps']>(key: K): AppReferential['maps'][K] => {
    return referential.value?.maps?.[key] || {} as AppReferential['maps'][K]
  }

  const getReferentialOptions = <K extends keyof AppReferential['options']>(key: K): AppReferential['options'][K] => {
    return referential.value?.options?.[key] || []
  }

  return {
    referential,
    orderedCountryList,
    searchCountryRedirectedToGoboony,
    fetchReferential,
    getReferentialMaps,
    getReferentialOptions,
  }
})
