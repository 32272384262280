import type { QueryParamConfig } from '~/types/commons'

export const useHeadCanonical = ({ params }: { params?: MaybeRef<QueryParamConfig[]> } = {}) => {
  const requestUrl = useRequestURL()
  const paramsValue = toValue(params)

  let url = new URL(requestUrl)
  if (paramsValue?.length) {
    url = getURLWithQueryParams({ url, params: paramsValue })
  }

  useHead({
    meta: [
      getMetaProperty({ property: 'og:url', content: url.href }),
    ],
    link: [
      { key: 'canonical', rel: 'canonical', href: url.href },
    ],
  })
}
