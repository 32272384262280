import type { QueryParamConfig } from '~/types/commons'

export const getURLWithQueryParams = ({
  url,
  params,
}: {
  url: URL
  params: MaybeRef<QueryParamConfig[]>
}) => {
  const { origin, pathname, searchParams } = url
  const cleanUrl = new URL(pathname, origin)
  // by default sort alphabetically to avoid duplicate indexed URLs
  const defaultSort = (arr: string[]) => arr.sort()

  toValue(params).forEach(({ key, sort }) => {
    const sortingFunction = sort ?? defaultSort
    sortingFunction(searchParams.getAll(key)).forEach(paramValue => cleanUrl.searchParams.append(key, paramValue))
  })

  return cleanUrl
}
