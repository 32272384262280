<script setup lang="ts">
import type { Organization, Product } from 'schema-dts'

const { stats } = useStatsStore()
const { seoImageHref } = useSeoImageHref()
const image = seoImageHref()
const { $i18n: { t } } = useNuxtApp()
const description = t('commons.meta.description')
const yescapaProductMicrodata: Product = {
  '@type': 'Product',
  'name': 'Yescapa',
  image,
  description,
  'aggregateRating': {
    '@type': 'AggregateRating',
    'bestRating': 5,
    'worstRating': 0,
    'ratingValue': stats.average,
    'reviewCount': stats.count,
  },
}

const requestUrl = useRequestURL()
const { routing } = useRoutingStore()
type RoutingSameAs = 'twitter' | 'facebook' | 'instagram' | 'pinterest' | 'youtube'
const routingSameAs: RoutingSameAs[] = ['twitter', 'facebook', 'instagram', 'pinterest', 'youtube']
const sameAs = routingSameAs.reduce<string[]>((acc, cur) => {
  if (routing && cur in routing) {
    acc.push(routing[cur])
  }
  return acc
}, [])
const yescapaOrganizationMicrodata: Organization = {
  '@type': 'Organization',
  'name': 'Yescapa',
  'url': requestUrl.origin,
  'logo': '/icon.png',
}

if (sameAs.length) {
  yescapaOrganizationMicrodata.sameAs = sameAs
}

const { referential } = useReferentialStore()
if (referential?.phone_number) {
  yescapaOrganizationMicrodata.contactPoint = [
    {
      '@type': 'ContactPoint',
      'telephone': referential.phone_number,
      'contactType': 'customer service',
    },
  ]
}

const { localeProperties: { yscCode } } = useLocaleProperties()
const { public: { app: { favicon }, facebook: { appId } } } = useRuntimeConfig()

useHead({
  htmlAttrs: {
    lang: yscCode,
  },
  templateParams: {
    site: {
      name: 'Yescapa',
    },
    separator: '-',
  },
  titleTemplate: '%s %separator %site.name',
  meta: [
    getMetaProperty({ property: 'og:site_name', content: 'Yescapa' }),
    getMetaProperty({ property: 'og:type', content: 'website' }),
    getMetaProperty({ property: 'fb:app_id', content: appId }),
    getMetaProperty({ property: 'og:locale', content: yscCode }),
    getHeadMetaName({ name: 'twitter:card', content: 'summary_large_image' }),
    getHeadMetaName({ name: 'twitter:site', content: '@Yescapa' }),
    getHeadMetaName({ name: 'twitter:creator', content: 'Yescapa' }),
    getMetaProperty({ property: 'og:image', content: image }),
    getHeadMetaName({ name: 'twitter:image', content: image }),
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: `/${favicon || 'icon.png'}` },
  ],
  script: [
    getJsonLDScript(yescapaProductMicrodata),
    getJsonLDScript(yescapaOrganizationMicrodata),
  ],
})

useHeadCanonical()
useSeoText()
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <AppToastGroup />
  </div>
</template>
