import { default as cancel_45insurancej5jEyl0AIMMeta } from "/home/node/app/pages/booking-payment/cancel-insurance.vue?macro=true";
import { default as insuranceOacB4hkuD8Meta } from "/home/node/app/pages/booking-payment/insurance.vue?macro=true";
import { default as overviews4j2azBPnCMeta } from "/home/node/app/pages/booking-payment/overview.vue?macro=true";
import { default as payment_45errorKLw1HM03tOMeta } from "/home/node/app/pages/booking-payment/payment-error.vue?macro=true";
import { default as payment_45finalv7Pcr8oR7UMeta } from "/home/node/app/pages/booking-payment/payment-final.vue?macro=true";
import { default as payment_45waiting0lD5tiJm8tMeta } from "/home/node/app/pages/booking-payment/payment-waiting.vue?macro=true";
import { default as paymentN3R8atOHIwMeta } from "/home/node/app/pages/booking-payment/payment.vue?macro=true";
import { default as successaw5LpbDVxOMeta } from "/home/node/app/pages/booking-payment/success.vue?macro=true";
import { default as user_45detailsoeA2yESGGsMeta } from "/home/node/app/pages/booking-payment/user-details.vue?macro=true";
import { default as booking_45paymentj9nLxc9ms6Meta } from "/home/node/app/pages/booking-payment.vue?macro=true";
import { default as _91seats_93_45seats12OjNK9S17Meta } from "/home/node/app/pages/camper-type/[slug]/[seats]-seats.vue?macro=true";
import { default as indexTPb3CvkSsHMeta } from "/home/node/app/pages/camper-type/[slug]/index.vue?macro=true";
import { default as index9H3btlEoZGMeta } from "/home/node/app/pages/campers/[id]/index.vue?macro=true";
import { default as successWGVhlQsfOHMeta } from "/home/node/app/pages/campers/[id]/request/[request_id]/success.vue?macro=true";
import { default as confort_45optionsNocZQQwYQ7Meta } from "/home/node/app/pages/campers/[id]/request/confort-options.vue?macro=true";
import { default as insurancePaUo9oCLXUMeta } from "/home/node/app/pages/campers/[id]/request/insurance.vue?macro=true";
import { default as message_45ownerAxVQqZzeAlMeta } from "/home/node/app/pages/campers/[id]/request/message-owner.vue?macro=true";
import { default as summarykdQiodod7JMeta } from "/home/node/app/pages/campers/[id]/request/summary.vue?macro=true";
import { default as travel_45detailsiN5IVg1X7zMeta } from "/home/node/app/pages/campers/[id]/request/travel-details.vue?macro=true";
import { default as requestVtwpDiFGj3Meta } from "/home/node/app/pages/campers/[id]/request.vue?macro=true";
import { default as _91id_93pSnuVIoGT8Meta } from "/home/node/app/pages/campers/[id].vue?macro=true";
import { default as _91slug_93enorWH92eVMeta } from "/home/node/app/pages/campervan-hire/[slug].vue?macro=true";
import { default as all_45reviews20GERpCJ8kMeta } from "/home/node/app/pages/community/all-reviews.vue?macro=true";
import { default as pictures_45walluqraNpnJCbMeta } from "/home/node/app/pages/community/pictures-wall.vue?macro=true";
import { default as communityAI18jDP4xMMeta } from "/home/node/app/pages/community.vue?macro=true";
import { default as confidentialityi3LGkQhU8cMeta } from "/home/node/app/pages/confidentiality.vue?macro=true";
import { default as indexZIV0loyEBlMeta } from "/home/node/app/pages/d/account/documents-guest/[id_doc]/index.vue?macro=true";
import { default as uploadvYPs0tUSMwMeta } from "/home/node/app/pages/d/account/documents-guest/[id_doc]/upload.vue?macro=true";
import { default as _91id_doc_93bbKzGfP3ApMeta } from "/home/node/app/pages/d/account/documents-guest/[id_doc].vue?macro=true";
import { default as driver_45identityE9fBqyirSUMeta } from "/home/node/app/pages/d/account/documents-guest/driver-identity.vue?macro=true";
import { default as indexR6BsmmkQqxMeta } from "/home/node/app/pages/d/account/documents-guest/index.vue?macro=true";
import { default as documents_45guestJjV3WC3itKMeta } from "/home/node/app/pages/d/account/documents-guest.vue?macro=true";
import { default as dateL6npNJJ9SJMeta } from "/home/node/app/pages/d/account/documents-owner/[id_doc]/date.vue?macro=true";
import { default as idnD1L7MtVYdZMeta } from "/home/node/app/pages/d/account/documents-owner/[id_doc]/idn.vue?macro=true";
import { default as indexL3kVGKyqkCMeta } from "/home/node/app/pages/d/account/documents-owner/[id_doc]/index.vue?macro=true";
import { default as uploadYuDYEPRksvMeta } from "/home/node/app/pages/d/account/documents-owner/[id_doc]/upload.vue?macro=true";
import { default as _91id_doc_93iD5HvcnMIMMeta } from "/home/node/app/pages/d/account/documents-owner/[id_doc].vue?macro=true";
import { default as ibanPTlYgM6f0UMeta } from "/home/node/app/pages/d/account/documents-owner/iban.vue?macro=true";
import { default as indexlVgagaJ9EwMeta } from "/home/node/app/pages/d/account/documents-owner/index.vue?macro=true";
import { default as tax_identification_numberL3VRx1AnvEMeta } from "/home/node/app/pages/d/account/documents-owner/tax_identification_number.vue?macro=true";
import { default as ubo_45declarationuRSPrFHdy4Meta } from "/home/node/app/pages/d/account/documents-owner/ubo-declaration.vue?macro=true";
import { default as documents_45ownerBTajwBhzkxMeta } from "/home/node/app/pages/d/account/documents-owner.vue?macro=true";
import { default as password6S7NtBaSOvMeta } from "/home/node/app/pages/d/account/password.vue?macro=true";
import { default as payinstkpM00grh3Meta } from "/home/node/app/pages/d/account/payins.vue?macro=true";
import { default as payoutsQ1KVw2QRi6Meta } from "/home/node/app/pages/d/account/payouts.vue?macro=true";
import { default as professionalRtryLP6VdbMeta } from "/home/node/app/pages/d/account/professional.vue?macro=true";
import { default as certify_45phonebrKRFigxTGMeta } from "/home/node/app/pages/d/account/profile/certify-phone.vue?macro=true";
import { default as deleteBj3z6Af2uuMeta } from "/home/node/app/pages/d/account/profile/delete.vue?macro=true";
import { default as indexvdmkfoh7A2Meta } from "/home/node/app/pages/d/account/profile/index.vue?macro=true";
import { default as profile9i1D1tnKl9Meta } from "/home/node/app/pages/d/account/profile.vue?macro=true";
import { default as rental_45creditsJy9UbyeJYXMeta } from "/home/node/app/pages/d/account/rental-credits.vue?macro=true";
import { default as account65VoKHPDlzMeta } from "/home/node/app/pages/d/account.vue?macro=true";
import { default as extensionsrraCSnWosmMeta } from "/home/node/app/pages/d/bookings/[id]/extensions.vue?macro=true";
import { default as indexlJDIqhexcWMeta } from "/home/node/app/pages/d/bookings/[id]/index.vue?macro=true";
import { default as _91id_93HIidQlFlXpMeta } from "/home/node/app/pages/d/bookings/[id].vue?macro=true";
import { default as index9bGD7AIHU9Meta } from "/home/node/app/pages/d/bookings/index.vue?macro=true";
import { default as calendarV7vbwGswqGMeta } from "/home/node/app/pages/d/campers/[id]/calendar.vue?macro=true";
import { default as dateVXF1V2H4G3Meta } from "/home/node/app/pages/d/campers/[id]/documents/[id_doc]/date.vue?macro=true";
import { default as indexMgahjTHtPOMeta } from "/home/node/app/pages/d/campers/[id]/documents/[id_doc]/index.vue?macro=true";
import { default as uploadqtuo856EEMMeta } from "/home/node/app/pages/d/campers/[id]/documents/[id_doc]/upload.vue?macro=true";
import { default as _91id_doc_933agLAXMg3UMeta } from "/home/node/app/pages/d/campers/[id]/documents/[id_doc].vue?macro=true";
import { default as indexeUp2Pw1U1PMeta } from "/home/node/app/pages/d/campers/[id]/documents/index.vue?macro=true";
import { default as documentsQSXLMw6MBdMeta } from "/home/node/app/pages/d/campers/[id]/documents.vue?macro=true";
import { default as indexQC2DUy5RxLMeta } from "/home/node/app/pages/d/campers/[id]/index.vue?macro=true";
import { default as picturesVSN39WkpNYMeta } from "/home/node/app/pages/d/campers/[id]/pictures.vue?macro=true";
import { default as pricesMzdzZeulT0Meta } from "/home/node/app/pages/d/campers/[id]/prices.vue?macro=true";
import { default as settingsBj7enHIsGvMeta } from "/home/node/app/pages/d/campers/[id]/settings.vue?macro=true";
import { default as vehicleRPGehj9TtsMeta } from "/home/node/app/pages/d/campers/[id]/vehicle.vue?macro=true";
import { default as _91id_93j5X5wQEwDDMeta } from "/home/node/app/pages/d/campers/[id].vue?macro=true";
import { default as indexT40KiTzfBRMeta } from "/home/node/app/pages/d/campers/index.vue?macro=true";
import { default as chatsm7XqrQ3JKxMeta } from "/home/node/app/pages/d/chats.vue?macro=true";
import { default as indexqEMZQx03kLMeta } from "/home/node/app/pages/d/reviews/index.vue?macro=true";
import { default as received4Q9LpwOQr3Meta } from "/home/node/app/pages/d/reviews/received.vue?macro=true";
import { default as senttzCgF1Ln4GMeta } from "/home/node/app/pages/d/reviews/sent.vue?macro=true";
import { default as reviewsEusokx0ajJMeta } from "/home/node/app/pages/d/reviews.vue?macro=true";
import { default as emergencyrZ9Ybu7zmsMeta } from "/home/node/app/pages/d/trips/[id]/documents/[id_doc]/emergency.vue?macro=true";
import { default as indexr9GQP6IZqCMeta } from "/home/node/app/pages/d/trips/[id]/documents/[id_doc]/index.vue?macro=true";
import { default as uploadk4f6cCLPNiMeta } from "/home/node/app/pages/d/trips/[id]/documents/[id_doc]/upload.vue?macro=true";
import { default as _91id_doc_936I2CPEjEpEMeta } from "/home/node/app/pages/d/trips/[id]/documents/[id_doc].vue?macro=true";
import { default as indexgyRLKFsMJGMeta } from "/home/node/app/pages/d/trips/[id]/documents/index.vue?macro=true";
import { default as documentsSEjNLY4ncCMeta } from "/home/node/app/pages/d/trips/[id]/documents.vue?macro=true";
import { default as extensions9531EOdCfoMeta } from "/home/node/app/pages/d/trips/[id]/extensions.vue?macro=true";
import { default as index30VFH06xc7Meta } from "/home/node/app/pages/d/trips/[id]/index.vue?macro=true";
import { default as insurancert9rJhNjoHMeta } from "/home/node/app/pages/d/trips/[id]/insurance.vue?macro=true";
import { default as refundaa1ghQwHA9Meta } from "/home/node/app/pages/d/trips/[id]/refund.vue?macro=true";
import { default as second_45driverMePtemb2kmMeta } from "/home/node/app/pages/d/trips/[id]/upgrades/second-driver.vue?macro=true";
import { default as _91id_93ZlQxS8BBckMeta } from "/home/node/app/pages/d/trips/[id].vue?macro=true";
import { default as index1SaZJU2h1LMeta } from "/home/node/app/pages/d/trips/index.vue?macro=true";
import { default as _91slug_93nEAEB0Rf09Meta } from "/home/node/app/pages/d/wishlists/[slug].vue?macro=true";
import { default as indexq7fG8ZMiQkMeta } from "/home/node/app/pages/d/wishlists/index.vue?macro=true";
import { default as djM6ogKtK4PMeta } from "/home/node/app/pages/d.vue?macro=true";
import { default as about_45you7trBGKgRYVMeta } from "/home/node/app/pages/earnings/about-you.vue?macro=true";
import { default as owner302JcXfb66Meta } from "/home/node/app/pages/earnings/owner.vue?macro=true";
import { default as resultsE9fXQcE7rxMeta } from "/home/node/app/pages/earnings/results.vue?macro=true";
import { default as vehicle_45propertieslzAZCLGXOMMeta } from "/home/node/app/pages/earnings/vehicle-properties.vue?macro=true";
import { default as vehiclefZWgfvWCpOMeta } from "/home/node/app/pages/earnings/vehicle.vue?macro=true";
import { default as earningsfylTCQg7srMeta } from "/home/node/app/pages/earnings.vue?macro=true";
import { default as bookingKp9C3hPxgtMeta } from "/home/node/app/pages/f/booking/[id]/booking.vue?macro=true";
import { default as endgI3ywU09YfMeta } from "/home/node/app/pages/f/booking/[id]/end.vue?macro=true";
import { default as guestLzfIo2iK15Meta } from "/home/node/app/pages/f/booking/[id]/guest.vue?macro=true";
import { default as index3uSIKwullQMeta } from "/home/node/app/pages/f/booking/[id]/index.vue?macro=true";
import { default as bookingP0X4ICwGysMeta } from "/home/node/app/pages/f/booking.vue?macro=true";
import { default as _91_46_46_46all_93nCo3tpge2iMeta } from "/home/node/app/pages/f/campers/[id_camper]/[...all].vue?macro=true";
import { default as booking7orLhNqV4yMeta } from "/home/node/app/pages/f/trip/[id]/booking.vue?macro=true";
import { default as end1qjm8SI12VMeta } from "/home/node/app/pages/f/trip/[id]/end.vue?macro=true";
import { default as indexeUMrBOlyjBMeta } from "/home/node/app/pages/f/trip/[id]/index.vue?macro=true";
import { default as owner_45privateQMUH719p5zMeta } from "/home/node/app/pages/f/trip/[id]/owner-private.vue?macro=true";
import { default as owner82EGugyNPMMeta } from "/home/node/app/pages/f/trip/[id]/owner.vue?macro=true";
import { default as trippjuGCcgyNVMeta } from "/home/node/app/pages/f/trip.vue?macro=true";
import { default as get_45apps0k1eertXfAMeta } from "/home/node/app/pages/get-apps.vue?macro=true";
import { default as _91id_93BCxsrvKSV1Meta } from "/home/node/app/pages/goboony/import/campers/[id].vue?macro=true";
import { default as how_45yescapa_45worksaPXO9Xqf74Meta } from "/home/node/app/pages/how-yescapa-works.vue?macro=true";
import { default as indexca63Ho8mnZMeta } from "/home/node/app/pages/index.vue?macro=true";
import { default as indexQzml3BRYQEMeta } from "/home/node/app/pages/login/index.vue?macro=true";
import { default as log_45asUoDk2v3M7WMeta } from "/home/node/app/pages/login/log-as.vue?macro=true";
import { default as loginNK2g5GJ8AIMeta } from "/home/node/app/pages/login.vue?macro=true";
import { default as endJpoOZDk7iiMeta } from "/home/node/app/pages/onboarding/step/end.vue?macro=true";
import { default as index1JwFaWQkgFMeta } from "/home/node/app/pages/onboarding/step/index.vue?macro=true";
import { default as product_45priceCBQVELOybgMeta } from "/home/node/app/pages/onboarding/step/product-price.vue?macro=true";
import { default as vehicle_45locationsz42L1PKW4Meta } from "/home/node/app/pages/onboarding/step/vehicle-location.vue?macro=true";
import { default as vehicle_45picturesescRObdBC3Meta } from "/home/node/app/pages/onboarding/step/vehicle-pictures.vue?macro=true";
import { default as vehicle_45propertiesKQ90vBGVSoMeta } from "/home/node/app/pages/onboarding/step/vehicle-properties.vue?macro=true";
import { default as vehicle_45type2Q32quARKgMeta } from "/home/node/app/pages/onboarding/step/vehicle-type.vue?macro=true";
import { default as onboardingLRANYxpSPgMeta } from "/home/node/app/pages/onboarding.vue?macro=true";
import { default as frontrj5B0bF64EMeta } from "/home/node/app/pages/ping/front.vue?macro=true";
import { default as confidentiality26auMxjk8iMeta } from "/home/node/app/pages/privacy/confidentiality.vue?macro=true";
import { default as cookiesMJJFxQltcrMeta } from "/home/node/app/pages/privacy/cookies.vue?macro=true";
import { default as privacyEIXpJIzdvxMeta } from "/home/node/app/pages/privacy.vue?macro=true";
import { default as rent_45my_45motorhomemQ8T0hqCyQMeta } from "/home/node/app/pages/rent-my-motorhome.vue?macro=true";
import { default as rv_45hire_45familyOKCMDcITPsMeta } from "/home/node/app/pages/rv-hire-family.vue?macro=true";
import { default as rv_45hire_45with_45pets8fcUpbBLgnMeta } from "/home/node/app/pages/rv-hire-with-pets.vue?macro=true";
import { default as sFQFIYzv2cLMeta } from "/home/node/app/pages/s.vue?macro=true";
import { default as surftrip2aJ7SOUVwiMeta } from "/home/node/app/pages/surftrip.vue?macro=true";
import { default as termsBh5FLj1bAHMeta } from "/home/node/app/pages/terms.vue?macro=true";
import { default as hit_45the_45roadvKt2b0UGv3Meta } from "/home/node/app/pages/travelers/hit-the-road.vue?macro=true";
import { default as rv_45beginner7iszdRiqVYMeta } from "/home/node/app/pages/travelers/rv-beginner.vue?macro=true";
import { default as travelersg45Ucj2rY4Meta } from "/home/node/app/pages/travelers.vue?macro=true";
import { default as we_45love_45california8T1I4ADBBiMeta } from "/home/node/app/pages/we-love-california.vue?macro=true";
export default [
  {
    name: "booking-payment",
    path: "/booking-payment",
    meta: booking_45paymentj9nLxc9ms6Meta || {},
    component: () => import("/home/node/app/pages/booking-payment.vue").then(m => m.default || m),
    children: [
  {
    name: "booking-payment-cancel-insurance",
    path: "cancel-insurance",
    component: () => import("/home/node/app/pages/booking-payment/cancel-insurance.vue").then(m => m.default || m)
  },
  {
    name: "booking-payment-insurance",
    path: "insurance",
    component: () => import("/home/node/app/pages/booking-payment/insurance.vue").then(m => m.default || m)
  },
  {
    name: "booking-payment-overview",
    path: "overview",
    component: () => import("/home/node/app/pages/booking-payment/overview.vue").then(m => m.default || m)
  },
  {
    name: "booking-payment-payment-error",
    path: "payment-error",
    meta: payment_45errorKLw1HM03tOMeta || {},
    component: () => import("/home/node/app/pages/booking-payment/payment-error.vue").then(m => m.default || m)
  },
  {
    name: "booking-payment-payment-final",
    path: "payment-final",
    component: () => import("/home/node/app/pages/booking-payment/payment-final.vue").then(m => m.default || m)
  },
  {
    name: "booking-payment-payment-waiting",
    path: "payment-waiting",
    component: () => import("/home/node/app/pages/booking-payment/payment-waiting.vue").then(m => m.default || m)
  },
  {
    name: "booking-payment-payment",
    path: "payment",
    component: () => import("/home/node/app/pages/booking-payment/payment.vue").then(m => m.default || m)
  },
  {
    name: "booking-payment-success",
    path: "success",
    component: () => import("/home/node/app/pages/booking-payment/success.vue").then(m => m.default || m)
  },
  {
    name: "booking-payment-user-details",
    path: "user-details",
    component: () => import("/home/node/app/pages/booking-payment/user-details.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "camper-type-slug-seats-seats",
    path: "/camper-type/:slug()/:seats()-seats",
    meta: _91seats_93_45seats12OjNK9S17Meta || {},
    component: () => import("/home/node/app/pages/camper-type/[slug]/[seats]-seats.vue").then(m => m.default || m)
  },
  {
    name: "camper-type-slug",
    path: "/camper-type/:slug()",
    component: () => import("/home/node/app/pages/camper-type/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pSnuVIoGT8Meta?.name,
    path: "/campers/:id()",
    meta: _91id_93pSnuVIoGT8Meta || {},
    component: () => import("/home/node/app/pages/campers/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "campers-id",
    path: "",
    component: () => import("/home/node/app/pages/campers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "campers-id-request",
    path: "request",
    meta: requestVtwpDiFGj3Meta || {},
    component: () => import("/home/node/app/pages/campers/[id]/request.vue").then(m => m.default || m),
    children: [
  {
    name: "campers-id-request-request_id-success",
    path: ":request_id()/success",
    component: () => import("/home/node/app/pages/campers/[id]/request/[request_id]/success.vue").then(m => m.default || m)
  },
  {
    name: "campers-id-request-confort-options",
    path: "confort-options",
    meta: confort_45optionsNocZQQwYQ7Meta || {},
    component: () => import("/home/node/app/pages/campers/[id]/request/confort-options.vue").then(m => m.default || m)
  },
  {
    name: "campers-id-request-insurance",
    path: "insurance",
    meta: insurancePaUo9oCLXUMeta || {},
    component: () => import("/home/node/app/pages/campers/[id]/request/insurance.vue").then(m => m.default || m)
  },
  {
    name: "campers-id-request-message-owner",
    path: "message-owner",
    meta: message_45ownerAxVQqZzeAlMeta || {},
    component: () => import("/home/node/app/pages/campers/[id]/request/message-owner.vue").then(m => m.default || m)
  },
  {
    name: "campers-id-request-summary",
    path: "summary",
    meta: summarykdQiodod7JMeta || {},
    component: () => import("/home/node/app/pages/campers/[id]/request/summary.vue").then(m => m.default || m)
  },
  {
    name: "campers-id-request-travel-details",
    path: "travel-details",
    meta: travel_45detailsiN5IVg1X7zMeta || {},
    component: () => import("/home/node/app/pages/campers/[id]/request/travel-details.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "campervan-hire-slug",
    path: "/campervan-hire/:slug()",
    meta: _91slug_93enorWH92eVMeta || {},
    component: () => import("/home/node/app/pages/campervan-hire/[slug].vue").then(m => m.default || m)
  },
  {
    name: "community",
    path: "/community",
    meta: communityAI18jDP4xMMeta || {},
    component: () => import("/home/node/app/pages/community.vue").then(m => m.default || m),
    children: [
  {
    name: "community-all-reviews",
    path: "all-reviews",
    meta: all_45reviews20GERpCJ8kMeta || {},
    component: () => import("/home/node/app/pages/community/all-reviews.vue").then(m => m.default || m)
  },
  {
    name: "community-pictures-wall",
    path: "pictures-wall",
    meta: pictures_45walluqraNpnJCbMeta || {},
    component: () => import("/home/node/app/pages/community/pictures-wall.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "confidentiality",
    path: "/confidentiality",
    meta: confidentialityi3LGkQhU8cMeta || {},
    component: () => import("/home/node/app/pages/confidentiality.vue").then(m => m.default || m)
  },
  {
    name: "d",
    path: "/d",
    meta: djM6ogKtK4PMeta || {},
    component: () => import("/home/node/app/pages/d.vue").then(m => m.default || m),
    children: [
  {
    name: "d-account",
    path: "account",
    meta: account65VoKHPDlzMeta || {},
    component: () => import("/home/node/app/pages/d/account.vue").then(m => m.default || m),
    children: [
  {
    name: documents_45guestJjV3WC3itKMeta?.name,
    path: "documents-guest",
    component: () => import("/home/node/app/pages/d/account/documents-guest.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_doc_93bbKzGfP3ApMeta?.name,
    path: ":id_doc()",
    meta: _91id_doc_93bbKzGfP3ApMeta || {},
    component: () => import("/home/node/app/pages/d/account/documents-guest/[id_doc].vue").then(m => m.default || m),
    children: [
  {
    name: "d-account-documents-guest-id_doc",
    path: "",
    component: () => import("/home/node/app/pages/d/account/documents-guest/[id_doc]/index.vue").then(m => m.default || m)
  },
  {
    name: "d-account-documents-guest-id_doc-upload",
    path: "upload",
    component: () => import("/home/node/app/pages/d/account/documents-guest/[id_doc]/upload.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-account-documents-guest-driver-identity",
    path: "driver-identity",
    component: () => import("/home/node/app/pages/d/account/documents-guest/driver-identity.vue").then(m => m.default || m)
  },
  {
    name: "d-account-documents-guest",
    path: "",
    component: () => import("/home/node/app/pages/d/account/documents-guest/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: documents_45ownerBTajwBhzkxMeta?.name,
    path: "documents-owner",
    component: () => import("/home/node/app/pages/d/account/documents-owner.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_doc_93iD5HvcnMIMMeta?.name,
    path: ":id_doc()",
    meta: _91id_doc_93iD5HvcnMIMMeta || {},
    component: () => import("/home/node/app/pages/d/account/documents-owner/[id_doc].vue").then(m => m.default || m),
    children: [
  {
    name: "d-account-documents-owner-id_doc-date",
    path: "date",
    component: () => import("/home/node/app/pages/d/account/documents-owner/[id_doc]/date.vue").then(m => m.default || m)
  },
  {
    name: "d-account-documents-owner-id_doc-idn",
    path: "idn",
    component: () => import("/home/node/app/pages/d/account/documents-owner/[id_doc]/idn.vue").then(m => m.default || m)
  },
  {
    name: "d-account-documents-owner-id_doc",
    path: "",
    component: () => import("/home/node/app/pages/d/account/documents-owner/[id_doc]/index.vue").then(m => m.default || m)
  },
  {
    name: "d-account-documents-owner-id_doc-upload",
    path: "upload",
    component: () => import("/home/node/app/pages/d/account/documents-owner/[id_doc]/upload.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-account-documents-owner-iban",
    path: "iban",
    component: () => import("/home/node/app/pages/d/account/documents-owner/iban.vue").then(m => m.default || m)
  },
  {
    name: "d-account-documents-owner",
    path: "",
    component: () => import("/home/node/app/pages/d/account/documents-owner/index.vue").then(m => m.default || m)
  },
  {
    name: "d-account-documents-owner-tax_identification_number",
    path: "tax_identification_number",
    component: () => import("/home/node/app/pages/d/account/documents-owner/tax_identification_number.vue").then(m => m.default || m)
  },
  {
    name: "d-account-documents-owner-ubo-declaration",
    path: "ubo-declaration",
    component: () => import("/home/node/app/pages/d/account/documents-owner/ubo-declaration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-account-password",
    path: "password",
    component: () => import("/home/node/app/pages/d/account/password.vue").then(m => m.default || m)
  },
  {
    name: "d-account-payins",
    path: "payins",
    meta: payinstkpM00grh3Meta || {},
    component: () => import("/home/node/app/pages/d/account/payins.vue").then(m => m.default || m)
  },
  {
    name: "d-account-payouts",
    path: "payouts",
    meta: payoutsQ1KVw2QRi6Meta || {},
    component: () => import("/home/node/app/pages/d/account/payouts.vue").then(m => m.default || m)
  },
  {
    name: "d-account-professional",
    path: "professional",
    component: () => import("/home/node/app/pages/d/account/professional.vue").then(m => m.default || m)
  },
  {
    name: profile9i1D1tnKl9Meta?.name,
    path: "profile",
    component: () => import("/home/node/app/pages/d/account/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "d-account-profile-certify-phone",
    path: "certify-phone",
    component: () => import("/home/node/app/pages/d/account/profile/certify-phone.vue").then(m => m.default || m)
  },
  {
    name: "d-account-profile-delete",
    path: "delete",
    component: () => import("/home/node/app/pages/d/account/profile/delete.vue").then(m => m.default || m)
  },
  {
    name: "d-account-profile",
    path: "",
    component: () => import("/home/node/app/pages/d/account/profile/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-account-rental-credits",
    path: "rental-credits",
    meta: rental_45creditsJy9UbyeJYXMeta || {},
    component: () => import("/home/node/app/pages/d/account/rental-credits.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93HIidQlFlXpMeta?.name,
    path: "bookings/:id()",
    meta: _91id_93HIidQlFlXpMeta || {},
    component: () => import("/home/node/app/pages/d/bookings/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "d-bookings-id-extensions",
    path: "extensions",
    component: () => import("/home/node/app/pages/d/bookings/[id]/extensions.vue").then(m => m.default || m)
  },
  {
    name: "d-bookings-id",
    path: "",
    component: () => import("/home/node/app/pages/d/bookings/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-bookings",
    path: "bookings",
    meta: index9bGD7AIHU9Meta || {},
    component: () => import("/home/node/app/pages/d/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93j5X5wQEwDDMeta?.name,
    path: "campers/:id()",
    meta: _91id_93j5X5wQEwDDMeta || {},
    component: () => import("/home/node/app/pages/d/campers/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "d-campers-id-calendar",
    path: "calendar",
    component: () => import("/home/node/app/pages/d/campers/[id]/calendar.vue").then(m => m.default || m)
  },
  {
    name: documentsQSXLMw6MBdMeta?.name,
    path: "documents",
    component: () => import("/home/node/app/pages/d/campers/[id]/documents.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_doc_933agLAXMg3UMeta?.name,
    path: ":id_doc()",
    meta: _91id_doc_933agLAXMg3UMeta || {},
    component: () => import("/home/node/app/pages/d/campers/[id]/documents/[id_doc].vue").then(m => m.default || m),
    children: [
  {
    name: "d-campers-id-documents-id_doc-date",
    path: "date",
    component: () => import("/home/node/app/pages/d/campers/[id]/documents/[id_doc]/date.vue").then(m => m.default || m)
  },
  {
    name: "d-campers-id-documents-id_doc",
    path: "",
    component: () => import("/home/node/app/pages/d/campers/[id]/documents/[id_doc]/index.vue").then(m => m.default || m)
  },
  {
    name: "d-campers-id-documents-id_doc-upload",
    path: "upload",
    component: () => import("/home/node/app/pages/d/campers/[id]/documents/[id_doc]/upload.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-campers-id-documents",
    path: "",
    component: () => import("/home/node/app/pages/d/campers/[id]/documents/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-campers-id",
    path: "",
    component: () => import("/home/node/app/pages/d/campers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "d-campers-id-pictures",
    path: "pictures",
    component: () => import("/home/node/app/pages/d/campers/[id]/pictures.vue").then(m => m.default || m)
  },
  {
    name: "d-campers-id-prices",
    path: "prices",
    component: () => import("/home/node/app/pages/d/campers/[id]/prices.vue").then(m => m.default || m)
  },
  {
    name: "d-campers-id-settings",
    path: "settings",
    component: () => import("/home/node/app/pages/d/campers/[id]/settings.vue").then(m => m.default || m)
  },
  {
    name: "d-campers-id-vehicle",
    path: "vehicle",
    component: () => import("/home/node/app/pages/d/campers/[id]/vehicle.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-campers",
    path: "campers",
    meta: indexT40KiTzfBRMeta || {},
    component: () => import("/home/node/app/pages/d/campers/index.vue").then(m => m.default || m)
  },
  {
    name: "d-chats",
    path: "chats",
    component: () => import("/home/node/app/pages/d/chats.vue").then(m => m.default || m)
  },
  {
    name: reviewsEusokx0ajJMeta?.name,
    path: "reviews",
    component: () => import("/home/node/app/pages/d/reviews.vue").then(m => m.default || m),
    children: [
  {
    name: "d-reviews",
    path: "",
    component: () => import("/home/node/app/pages/d/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: "d-reviews-received",
    path: "received",
    component: () => import("/home/node/app/pages/d/reviews/received.vue").then(m => m.default || m)
  },
  {
    name: "d-reviews-sent",
    path: "sent",
    component: () => import("/home/node/app/pages/d/reviews/sent.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93ZlQxS8BBckMeta?.name,
    path: "trips/:id()",
    meta: _91id_93ZlQxS8BBckMeta || {},
    component: () => import("/home/node/app/pages/d/trips/[id].vue").then(m => m.default || m),
    children: [
  {
    name: documentsSEjNLY4ncCMeta?.name,
    path: "documents",
    component: () => import("/home/node/app/pages/d/trips/[id]/documents.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_doc_936I2CPEjEpEMeta?.name,
    path: ":id_doc()",
    meta: _91id_doc_936I2CPEjEpEMeta || {},
    component: () => import("/home/node/app/pages/d/trips/[id]/documents/[id_doc].vue").then(m => m.default || m),
    children: [
  {
    name: "d-trips-id-documents-id_doc-emergency",
    path: "emergency",
    component: () => import("/home/node/app/pages/d/trips/[id]/documents/[id_doc]/emergency.vue").then(m => m.default || m)
  },
  {
    name: "d-trips-id-documents-id_doc",
    path: "",
    meta: indexr9GQP6IZqCMeta || {},
    component: () => import("/home/node/app/pages/d/trips/[id]/documents/[id_doc]/index.vue").then(m => m.default || m)
  },
  {
    name: "d-trips-id-documents-id_doc-upload",
    path: "upload",
    component: () => import("/home/node/app/pages/d/trips/[id]/documents/[id_doc]/upload.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-trips-id-documents",
    path: "",
    component: () => import("/home/node/app/pages/d/trips/[id]/documents/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-trips-id-extensions",
    path: "extensions",
    meta: extensions9531EOdCfoMeta || {},
    component: () => import("/home/node/app/pages/d/trips/[id]/extensions.vue").then(m => m.default || m)
  },
  {
    name: "d-trips-id",
    path: "",
    component: () => import("/home/node/app/pages/d/trips/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "d-trips-id-insurance",
    path: "insurance",
    meta: insurancert9rJhNjoHMeta || {},
    component: () => import("/home/node/app/pages/d/trips/[id]/insurance.vue").then(m => m.default || m)
  },
  {
    name: "d-trips-id-refund",
    path: "refund",
    component: () => import("/home/node/app/pages/d/trips/[id]/refund.vue").then(m => m.default || m)
  },
  {
    name: "d-trips-id-upgrades-second-driver",
    path: "upgrades/second-driver",
    meta: second_45driverMePtemb2kmMeta || {},
    component: () => import("/home/node/app/pages/d/trips/[id]/upgrades/second-driver.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "d-trips",
    path: "trips",
    meta: index1SaZJU2h1LMeta || {},
    component: () => import("/home/node/app/pages/d/trips/index.vue").then(m => m.default || m)
  },
  {
    name: "d-wishlists-slug",
    path: "wishlists/:slug()",
    component: () => import("/home/node/app/pages/d/wishlists/[slug].vue").then(m => m.default || m)
  },
  {
    name: "d-wishlists",
    path: "wishlists",
    component: () => import("/home/node/app/pages/d/wishlists/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "earnings",
    path: "/earnings",
    meta: earningsfylTCQg7srMeta || {},
    component: () => import("/home/node/app/pages/earnings.vue").then(m => m.default || m),
    children: [
  {
    name: "earnings-about-you",
    path: "about-you",
    component: () => import("/home/node/app/pages/earnings/about-you.vue").then(m => m.default || m)
  },
  {
    name: "earnings-owner",
    path: "owner",
    component: () => import("/home/node/app/pages/earnings/owner.vue").then(m => m.default || m)
  },
  {
    name: "earnings-results",
    path: "results",
    meta: resultsE9fXQcE7rxMeta || {},
    component: () => import("/home/node/app/pages/earnings/results.vue").then(m => m.default || m)
  },
  {
    name: "earnings-vehicle-properties",
    path: "vehicle-properties",
    component: () => import("/home/node/app/pages/earnings/vehicle-properties.vue").then(m => m.default || m)
  },
  {
    name: "earnings-vehicle",
    path: "vehicle",
    component: () => import("/home/node/app/pages/earnings/vehicle.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "f-booking",
    path: "/f/booking",
    meta: bookingP0X4ICwGysMeta || {},
    component: () => import("/home/node/app/pages/f/booking.vue").then(m => m.default || m),
    children: [
  {
    name: "f-booking-id-booking",
    path: ":id()/booking",
    component: () => import("/home/node/app/pages/f/booking/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "f-booking-id-end",
    path: ":id()/end",
    component: () => import("/home/node/app/pages/f/booking/[id]/end.vue").then(m => m.default || m)
  },
  {
    name: "f-booking-id-guest",
    path: ":id()/guest",
    component: () => import("/home/node/app/pages/f/booking/[id]/guest.vue").then(m => m.default || m)
  },
  {
    name: "f-booking-id",
    path: ":id()",
    component: () => import("/home/node/app/pages/f/booking/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "f-campers-id_camper-all",
    path: "/f/campers/:id_camper()/:all(.*)*",
    meta: _91_46_46_46all_93nCo3tpge2iMeta || {},
    component: () => import("/home/node/app/pages/f/campers/[id_camper]/[...all].vue").then(m => m.default || m)
  },
  {
    name: "f-trip",
    path: "/f/trip",
    meta: trippjuGCcgyNVMeta || {},
    component: () => import("/home/node/app/pages/f/trip.vue").then(m => m.default || m),
    children: [
  {
    name: "f-trip-id-booking",
    path: ":id()/booking",
    component: () => import("/home/node/app/pages/f/trip/[id]/booking.vue").then(m => m.default || m)
  },
  {
    name: "f-trip-id-end",
    path: ":id()/end",
    component: () => import("/home/node/app/pages/f/trip/[id]/end.vue").then(m => m.default || m)
  },
  {
    name: "f-trip-id",
    path: ":id()",
    component: () => import("/home/node/app/pages/f/trip/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "f-trip-id-owner-private",
    path: ":id()/owner-private",
    component: () => import("/home/node/app/pages/f/trip/[id]/owner-private.vue").then(m => m.default || m)
  },
  {
    name: "f-trip-id-owner",
    path: ":id()/owner",
    component: () => import("/home/node/app/pages/f/trip/[id]/owner.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "get-apps",
    path: "/get-apps",
    component: () => import("/home/node/app/pages/get-apps.vue").then(m => m.default || m)
  },
  {
    name: "goboony-import-campers-id",
    path: "/goboony/import/campers/:id()",
    meta: _91id_93BCxsrvKSV1Meta || {},
    component: () => import("/home/node/app/pages/goboony/import/campers/[id].vue").then(m => m.default || m)
  },
  {
    name: "how-yescapa-works",
    path: "/how-yescapa-works",
    component: () => import("/home/node/app/pages/how-yescapa-works.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/node/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginNK2g5GJ8AIMeta?.name,
    path: "/login",
    component: () => import("/home/node/app/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: "login",
    path: "",
    meta: indexQzml3BRYQEMeta || {},
    component: () => import("/home/node/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-log-as",
    path: "log-as",
    meta: log_45asUoDk2v3M7WMeta || {},
    component: () => import("/home/node/app/pages/login/log-as.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingLRANYxpSPgMeta || {},
    component: () => import("/home/node/app/pages/onboarding.vue").then(m => m.default || m),
    children: [
  {
    name: "onboarding-step-end",
    path: "step/end",
    component: () => import("/home/node/app/pages/onboarding/step/end.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step",
    path: "step",
    meta: index1JwFaWQkgFMeta || {},
    component: () => import("/home/node/app/pages/onboarding/step/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-product-price",
    path: "step/product-price",
    meta: product_45priceCBQVELOybgMeta || {},
    component: () => import("/home/node/app/pages/onboarding/step/product-price.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-vehicle-location",
    path: "step/vehicle-location",
    component: () => import("/home/node/app/pages/onboarding/step/vehicle-location.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-vehicle-pictures",
    path: "step/vehicle-pictures",
    component: () => import("/home/node/app/pages/onboarding/step/vehicle-pictures.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-vehicle-properties",
    path: "step/vehicle-properties",
    component: () => import("/home/node/app/pages/onboarding/step/vehicle-properties.vue").then(m => m.default || m)
  },
  {
    name: "onboarding-step-vehicle-type",
    path: "step/vehicle-type",
    component: () => import("/home/node/app/pages/onboarding/step/vehicle-type.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "ping-front",
    path: "/ping/front",
    meta: frontrj5B0bF64EMeta || {},
    component: () => import("/home/node/app/pages/ping/front.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyEIXpJIzdvxMeta || {},
    component: () => import("/home/node/app/pages/privacy.vue").then(m => m.default || m),
    children: [
  {
    name: "privacy-confidentiality",
    path: "confidentiality",
    component: () => import("/home/node/app/pages/privacy/confidentiality.vue").then(m => m.default || m)
  },
  {
    name: "privacy-cookies",
    path: "cookies",
    component: () => import("/home/node/app/pages/privacy/cookies.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "rent-my-motorhome",
    path: "/rent-my-motorhome",
    component: () => import("/home/node/app/pages/rent-my-motorhome.vue").then(m => m.default || m)
  },
  {
    name: "rv-hire-family",
    path: "/rv-hire-family",
    component: () => import("/home/node/app/pages/rv-hire-family.vue").then(m => m.default || m)
  },
  {
    name: "rv-hire-with-pets",
    path: "/rv-hire-with-pets",
    component: () => import("/home/node/app/pages/rv-hire-with-pets.vue").then(m => m.default || m)
  },
  {
    name: "s",
    path: "/s",
    meta: sFQFIYzv2cLMeta || {},
    component: () => import("/home/node/app/pages/s.vue").then(m => m.default || m)
  },
  {
    name: "surftrip",
    path: "/surftrip",
    component: () => import("/home/node/app/pages/surftrip.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/node/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "travelers",
    path: "/travelers",
    meta: travelersg45Ucj2rY4Meta || {},
    component: () => import("/home/node/app/pages/travelers.vue").then(m => m.default || m),
    children: [
  {
    name: "travelers-hit-the-road",
    path: "hit-the-road",
    component: () => import("/home/node/app/pages/travelers/hit-the-road.vue").then(m => m.default || m)
  },
  {
    name: "travelers-rv-beginner",
    path: "rv-beginner",
    component: () => import("/home/node/app/pages/travelers/rv-beginner.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "we-love-california",
    path: "/we-love-california",
    component: () => import("/home/node/app/pages/we-love-california.vue").then(m => m.default || m)
  }
]